import * as React from "react";
import deekshaMe from "../../assets/images/deek_formal.png";
import {
  Description,
  Hello,
  HomeContainer,
  IntroContainer,
  Introduction,
  IntroImgCnt,
  MeetMe,
  Name,
  Work,
} from "../../styles/web/webIndex.styles";
import { Layout } from "../layout";
import { Social } from "../socials/socials";

export const WebIndex = () => {
  const [loading, setLoading] = React.useState(true);
  const indexImage = React.useRef();
  React.useEffect(() => {
    const cur = indexImage.current as HTMLImageElement;
    if (cur.complete) setLoading(false);
  }, [indexImage]);
  return (
    <Layout displayLoader={loading}>
      <HomeContainer>
        <IntroImgCnt>
          <MeetMe
            src={deekshaMe}
            ref={indexImage}
            onLoad={() => {
              setLoading(false);
            }}
            loading={"eager"}
          />
        </IntroImgCnt>
        <IntroContainer>
          <Introduction>
            <Hello>Hello!</Hello>
            <Name>I'm, Deeksha Juneja</Name>
            <Description>
              <Work>
                A business woman, product manager, strategist, author, and
                makeup artist
              </Work>
            </Description>
          </Introduction>
          <Social />
        </IntroContainer>
      </HomeContainer>
    </Layout>
  );
};
