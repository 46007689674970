import * as React from "react";
import { IsMobile } from "../hooks/isMobile";
import { MobileIndex } from "../components/mobile/mobileIndex";
import { WebIndex } from "../components/web/webIndex";

const IndexPage = () => {
  if (typeof window === "undefined") return null;
  const isMobile = IsMobile();
  return isMobile ? <MobileIndex /> : <WebIndex />;
};

export default IndexPage;
